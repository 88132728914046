import React, { useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { NavItem } from './NavItem';
import { NavItemType } from '../../type';

interface Props {
  items?: Array<NavItemType>;
}

export const NavMenu = (props: Props) => {
  const { items } = props;
  // 当前选中的Key
  // const [selectedKey, setSelectedKey] = useState('');
  // 当前鼠标hover上的Key
  const [selectPanelKey, setPanelKey] = useState('');

  const router = useRouter();
  const { pathname } = router;

  const specialPaths = ['/csr', '/gongyi'];

  // csr和gongyi特殊处理 因为他们在这个路径下有对应的页面
  // itemPath：导航按钮的path currentPath：当前页面的path
  const isCurrentPath = (itemPath: string, currentPath: string): boolean => {
    // /csr 和 /gongyi
    if (itemPath === currentPath) return true;
    // /csr/xxx 和 /gongyi/xxx
    if (specialPaths.includes(itemPath) && currentPath.includes(itemPath)) return true;
    // /newsroom/xxx
    if (itemPath.includes('newsroom') && currentPath.includes('newsroom')) return true;
    return false;
  };

  const currentKey = useMemo(() => {
    let current = '';
    if (items) {
      for (let i = 0; i < items?.length; i++) {
        const item = items[i];
        // console.log(isCurrentPath(item.path, pathname))
        if (isCurrentPath(item.path, pathname)) {
          current = item.key;
          break;
        }
      }
    }
    return current;
  }, [items, pathname]);

  // 切换hover状态
  const onToggle = (key: string) => {
    // console.log(key);
    setPanelKey(key);
  };

  return (
    <nav className="hidden xl:flex xl:h-20 xl:items-center xl:justify-center xl:bg-transparent">
      <ul className="hidden xl:flex">
        {(items || []).map((_) => {
          const isHover = selectPanelKey === _.key;
          const { key, ...other } = _;
          // 如果没有子节点
          return (
            <NavItem {...other} key={key} uid={key} isHover={isHover} selectKey={currentKey} onToggle={onToggle} />
          );
        })}
      </ul>
    </nav>
  );
};
